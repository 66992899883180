import * as React from 'react'
import Helmet from 'react-helmet'

import PageHeader from '../components/PageHeader/PageHeader'
import Page from '../components/Page/Page'
import Projects from '../components/Projects/Projects'


const CategoryPage = ({ pageContext: { currentLang, categories, projects, translations, sections, currentCategory}}) => (
  <React.Fragment>
    <Helmet>
      <body className="has-fixed-nav" />
    </Helmet>

    <Page
      translations={translations}
      currentLang={currentLang}
    >
      <PageHeader />

      <Projects
        categories={categories}
        currentCategory={currentCategory}
        projects={projects}
        translations={translations}
        section={sections['projects']}
      />

    </Page>
  </React.Fragment>
)

export default CategoryPage
